import React from 'react';
import { Routers } from './router'
import 'taro-ui/dist/style/index.scss'
import './App.css';

function App() {
  return (
    <div className="App">
      <Routers />
    </div>
  );
}

export default App;

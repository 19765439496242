import React, { Suspense, lazy } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

export function Routers() {
  const NewExchange = lazy(() => import('./pages/newExchangeh5/exchange'))
  const Search = lazy(() => import('./pages/newExchangeh5/search'))
  const Result = lazy(() => import('./pages/newExchangeh5/result'))
  const Exchange = lazy(() => import('./pages/exchangeh5/exchange'))

  return (
    <Router>
      <Suspense>
        <Switch>
          {/* 兑换中心 三个页面 */}
          <Route path="/" exact component={NewExchange} />
          <Route path="/newExchange_search" component={Search} />
          <Route path="/newExchange_result" component={Result} />

          {/*  */}
          <Route path="/exchange/index" component={Exchange} />
        </Switch>
      </Suspense>
    </Router>
  )
}

